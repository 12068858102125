import { AddIcon, Box, Button, Grid, Typography } from "@enerbit/base";
import type { FC } from "react";
import { useFormContext } from "react-hook-form";
import type { OrderOperatorGetOrders } from "../../../models/IGetOrders";
import { addOrderOperator, useAppDispatch } from "../../../store";
import { addOperator } from "../../../store/slices/team-operators/teamOperators";
import { useAppSelector } from "../../../store/store";

interface Props {
    option: OrderOperatorGetOrders;
}

export const OptionSearchItem: FC<Props> = ({ option }) => {
    const { action } = useAppSelector((state) => state.initialForm);
    const dispatch = useAppDispatch();
    const { watch } = useFormContext();

    const OnAddOperator = () => {
        if (action?.type === "Create") {
            dispatch(addOperator(option));
        } else {
            dispatch(
                addOrderOperator({
                    order_id: watch("id"),
                    operator: option,
                }),
            );
        }
    };

    return (
        <Box sx={{ borderBottom: "1px solid #667085", zIndex: "0!important" }}>
            <Grid container>
                <Grid item xs={10} container spacing={2} p={2}>
                    {option.pii && (
                        <>
                            <Grid item xs={12} container spacing={2}>
                                <Grid item xs={4}>
                                    <Box>
                                        <Typography color="#667085">
                                            Nombre
                                        </Typography>
                                        <Typography
                                            color="#344054"
                                            fontWeight="700"
                                            fontSize="16px"
                                        >
                                            <b>{option.pii.full_name}</b>
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box>
                                        <Typography color="#667085">
                                            {option.pii.legal_id_type}:
                                        </Typography>
                                        <Typography
                                            color="#344054"
                                            fontWeight="700"
                                            fontSize="16px"
                                        >
                                            <b>{option.pii.legal_id_code}</b>
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box>
                                        <Typography color="#667085">
                                            Teléfono
                                        </Typography>
                                        <Typography
                                            color="#344054"
                                            fontWeight="700"
                                            fontSize="16px"
                                        >
                                            <b>{option.pii.phone}</b>
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12} container spacing={2}>
                        <Grid item xs={4}>
                            <Box display="flex" flexDirection="column">
                                <Typography color="#667085">
                                    Empresa enerbit
                                </Typography>
                                <Typography
                                    color="#344054"
                                    fontWeight="700"
                                    fontSize="16px"
                                >
                                    <b>{option.pii.company.name}</b>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box>
                                <Typography color="#667085">Rol</Typography>
                                <Typography
                                    color="#344054"
                                    fontWeight="700"
                                    fontSize="16px"
                                >
                                    <b>{option.pii.rol}</b>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box>
                                <Typography color="#667085">Correo</Typography>
                                <Typography
                                    color="#344054"
                                    fontWeight="700"
                                    fontSize="16px"
                                >
                                    <b>{option.pii.email}</b>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2} display="flex" alignItems="center">
                    <Button
                        id={`add-operator-${option.pii.email}`}
                        className="orange-button"
                        variant="contained"
                        size="small"
                        onClick={OnAddOperator}
                    >
                        <AddIcon fontSize="small" />
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};
