import { Grid, decodedJwt } from "@enerbit/base";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { BestTeamItem } from ".";
import { getTeamByCompanyId } from "../../../services";
import { searchTeams, useAppDispatch, useAppSelector } from "../../../store";
import { resetSelectOrder } from "../../../store/slices/initial-form/initialForm";
import { resetListTeam } from "../../../store/slices/team-operators/teamOperators";
import { EmptyData } from "../empty-data";
import BestTeamItemSkeleton from "./BestItemSkeleton";

const BestTeamContainer = () => {
    const [hasTemas, setHasTeams] = useState(false);
    const [isLoadingTeamCompany, setIsLoadingTeamCompany] = useState(false);
    const decodedToken = decodedJwt();

    const role = decodedToken?.role;
    const company_id = decodedToken?.company_id;

    const { loadingTeam, listTeams } = useAppSelector(
        (state) => state.teamOperators,
    );
    const { watch } = useFormContext();
    const { selectedOrder, addedEntities, measurementType, action } =
        useAppSelector((state) => state.initialForm);
    const dispatch = useAppDispatch();

    const checkTeamsCompany = async () => {
        setIsLoadingTeamCompany(true);
        try {
            const companyId = watch("form_data.assigned_to_company_id");
            if (companyId) {
                const teams = await getTeamByCompanyId(companyId);

                if (teams.length > 0) {
                    setHasTeams(true);
                } else {
                    setHasTeams(false);
                }
            }
        } catch (error) {
            return error;
        } finally {
            setIsLoadingTeamCompany(false);
        }
    };

    useEffect(() => {
        checkTeamsCompany();
    }, [watch("form_data.assigned_to_company_id")]);

    useEffect(() => {
        if (action?.type === "Create") {
            dispatch(resetSelectOrder());
        }

        dispatch(resetListTeam());
        if (
            addedEntities.length > 0 &&
            watch("planned_date_begin") &&
            watch("planned_date_end") &&
            watch("form_data.assigned_to_company_id") &&
            hasTemas
        ) {
            const state = addedEntities[0]?.department ?? watch("state");
            const city = addedEntities[0]?.city ?? watch("city");

            if (state && city) {
                dispatch(
                    searchTeams({
                        id: selectedOrder?.work_group_id ?? undefined,
                        state,
                        city,
                        start: watch("planned_date_begin"),
                        end: watch("planned_date_end"),
                        company_id: watch("form_data.assigned_to_company_id"),
                        // role === "contratista" ? company_id : undefined,
                        measurement_type: measurementType,
                    }),
                );
            }
        }
    }, [
        watch("planned_date_begin"),
        watch("planned_date_end"),
        selectedOrder,
        addedEntities,
        company_id,
        watch("state"),
        watch("city"),
        resetListTeam,
        measurementType,
        role,
        watch("form_data.assigned_to_company_id"),
        hasTemas,
    ]);

    return (
        <Grid container spacing={3} sx={{ pt: 3, ml: 0 }}>
            {loadingTeam || isLoadingTeamCompany ? (
                <>
                    {Array.from({ length: 3 }).map((_, index) => (
                        <BestTeamItemSkeleton key={index.toString()} />
                    ))}
                </>
            ) : hasTemas ? (
                <>
                    {listTeams.length > 0 ? (
                        <>
                            {listTeams.map((item, index) => (
                                <BestTeamItem
                                    item={item}
                                    key={index.toString()}
                                />
                            ))}
                        </>
                    ) : (
                        <>
                            {watch("planned_date_begin") &&
                                watch("planned_date_end") &&
                                watch("form_data.assigned_to_company_id") && (
                                    <Grid item xs={12}>
                                        <EmptyData
                                            message={
                                                "No hay cuadrillas disponibles para esta esta orden de servicio"
                                            }
                                        />
                                    </Grid>
                                )}
                        </>
                    )}
                </>
            ) : (
                <>
                    {watch("form_data.assigned_to_company_id") && (
                        <Grid item xs={12}>
                            <EmptyData
                                message={
                                    "Este aliado no cuenta con cuadrillas, comunícate con el aliado para crear las cuadrillas"
                                }
                            />
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    );
};

export default BestTeamContainer;
