import {
    Avatar,
    AvatarGroup,
    Box,
    Button,
    Grid,
    Tooltip,
    Typography,
    enerbitColors,
} from "@enerbit/base";
import { type FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import type { IWorkGroupsItem } from "../../../models";
import { stringToColor } from "../../../utils";

interface Props {
    item: IWorkGroupsItem;
}

const BestTeamItem: FC<Props> = ({ item }) => {
    const { watch, setValue } = useFormContext();

    const { city, state, operators } = item;

    const neutralColor = enerbitColors.neutral[300];
    const primaryColor = enerbitColors.primary.main;

    const teamName = useMemo(() => {
        const namesBeforeAt = operators.map(
            ({ username }) => username.split("@")[0],
        );
        const joinedNamesWithHyphen = namesBeforeAt.join("-");
        return `${joinedNamesWithHyphen}`;
    }, [operators]);

    const handleAddTeam = (id: string) => {
        setValue("work_group_id", id);
    };

    const selected = watch("work_group_id") === item.id;

    return (
        <Grid item xs={12} md={4}>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    border: `1px solid ${selected ? primaryColor : neutralColor}`,
                    borderRadius: "14px",
                    height: "100%",
                    padding: "16px",
                    gap: 2,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                        Cuadrilla {teamName}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        gap: 1,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: "auto",
                            gap: 2,
                        }}
                    >
                        <Box width={"75%"}>
                            <Typography variant="body1">
                                Departamento: <b>{state}</b>
                            </Typography>
                            <Typography
                                variant="body1"
                                style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                            >
                                Ciudad:{city ?? ""}
                            </Typography>
                        </Box>
                        <AvatarGroup
                            total={operators.length}
                            max={3}
                            sx={{
                                ".MuiAvatarGroup-avatar": {
                                    width: 25,
                                    height: 25,
                                    fontSize: 10,
                                },
                            }}
                        >
                            {operators.map(({ pii, username }, index) => (
                                <Tooltip
                                    title={pii?.full_name}
                                    key={index.toString()}
                                >
                                    <Avatar
                                        alt={username}
                                        src={
                                            pii?.form_data?.image_profile
                                                ?.photography_base64
                                        }
                                        sx={{
                                            width: 25,
                                            height: 25,
                                            backgroundColor:
                                                stringToColor(username),
                                            fontSize: 10,
                                        }}
                                    >
                                        {username.substring(0, 2).toUpperCase()}
                                    </Avatar>
                                </Tooltip>
                            ))}
                        </AvatarGroup>
                    </Box>
                </Box>
                {selected ? (
                    <Button
                        variant="contained"
                        onClick={() => setValue("work_group_id", null)}
                    >
                        Quitar
                    </Button>
                ) : (
                    <Button
                        variant="outlined"
                        onClick={() => handleAddTeam(item.id)}
                    >
                        Seleccionar
                    </Button>
                )}
            </Box>
        </Grid>
    );
};

export default BestTeamItem;
