import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import type {
    ITeamOperatorsState,
    IWorkGroupsItem,
    OrderOperatorGetOrders,
} from "../../../models";
import {
    addOrderOperator,
    deleteAllOrderOperators,
    deleteOrderOperator,
    getOperators,
    getTeam,
    getTeams,
    searchTeams,
} from "./thunk";

const initialState: ITeamOperatorsState = {
    loadingOperator: false,
    listOperators: [],
    addedOperators: [],
    notFoundOperator: false,
    errorOperator: false,
    listTeams: [],
    addedTeam: null,
    loadingTeams: false,
    loadingTeam: false,
    notFoundTeam: false,
    errorTeam: false,
};

const teamOperators = createSlice({
    name: "teamOperators",
    initialState,
    reducers: {
        resetListOperators: (state) => {
            state.listOperators = [];
        },
        loadOperators: (
            state,
            { payload }: PayloadAction<OrderOperatorGetOrders[]>,
        ) => {
            state.addedOperators = payload;
            state.listOperators = [];
        },
        addOperator: (
            state,
            { payload }: PayloadAction<OrderOperatorGetOrders>,
        ) => {
            const isInvalid = !!state.addedOperators.find(
                ({ operator_id }) => operator_id === payload.operator_id,
            );

            if (!isInvalid) {
                state.addedOperators = [...state.addedOperators, payload];
                state.listOperators = [];
            }
        },
        resetAddedNotificationEmail: (state) => {
            state.addedOperators = [];
        },
        handleDeleteOperator: (state, { payload }: PayloadAction<string>) => {
            state.addedOperators = state.addedOperators.filter(
                ({ operator_id }) => operator_id !== payload,
            );
        },
        resetAddedOperators: (state) => {
            state.addedOperators = [];
        },
        resetListTeam: (state) => {
            state.listTeams = [];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getOperators.pending, (state) => {
            state.loadingOperator = true;
        });
        builder.addCase(getOperators.fulfilled, (state, { payload }) => {
            state.loadingOperator = false;

            if (payload.length > 0) {
                state.listOperators = payload.filter((elementList) => {
                    return !state.addedOperators.some((elementAdded) => {
                        return (
                            elementAdded.operator_id === elementList.operator_id
                        );
                    });
                });
            } else {
                state.notFoundOperator = true;
            }
        });
        builder.addCase(getOperators.rejected, (state) => {
            state.loadingOperator = false;
        });
        builder.addCase(addOrderOperator.pending, (state) => {
            state.loadingOperator = true;
            state.errorOperator = false;
        });
        builder.addCase(addOrderOperator.fulfilled, (state, { payload }) => {
            state.loadingOperator = false;
            const isInvalid = state.addedOperators.find(
                ({ id }) => id === payload.id,
            );

            if (!isInvalid) {
                state.addedOperators = [...state.addedOperators, payload];
                state.listOperators = [];
            }
        });
        builder.addCase(addOrderOperator.rejected, (state) => {
            state.loadingOperator = false;
            state.errorOperator = true;
        });
        builder.addCase(deleteOrderOperator.pending, (state) => {
            state.loadingOperator = true;
            state.errorOperator = false;
        });
        builder.addCase(deleteOrderOperator.fulfilled, (state, { payload }) => {
            state.loadingOperator = false;
            state.addedOperators = state.addedOperators.filter(
                ({ id }) => id !== payload,
            );
        });
        builder.addCase(deleteOrderOperator.rejected, (state) => {
            state.loadingOperator = false;
            state.errorOperator = true;
        });
        builder.addCase(deleteAllOrderOperators.pending, (state) => {
            state.loadingOperator = true;
            state.errorOperator = false;
        });
        builder.addCase(deleteAllOrderOperators.fulfilled, (state) => {
            state.loadingOperator = false;
            state.addedOperators = [];
        });
        builder.addCase(deleteAllOrderOperators.rejected, (state) => {
            state.loadingOperator = false;
            state.errorOperator = true;
        });
        builder.addCase(getTeams.pending, (state) => {
            state.loadingTeams = true;
        });
        builder.addCase(getTeams.fulfilled, (state, { payload }) => {
            state.loadingTeams = false;

            if (payload.length > 0) {
                state.listTeams = payload.filter((elementList) => {
                    return state.addedTeam?.id !== elementList.id;
                });
            } else {
                state.notFoundTeam = true;
            }
        });
        builder.addCase(getTeams.rejected, (state) => {
            state.loadingTeams = false;
        });
        builder.addCase(getTeam.pending, (state) => {
            state.loadingTeam = true;
            state.addedTeam = null;
        });
        builder.addCase(getTeam.fulfilled, (state, { payload }) => {
            state.loadingTeam = false;
            state.addedTeam = payload;
            state.listTeams = [];
        });
        builder.addCase(getTeam.rejected, (state) => {
            state.loadingTeam = false;
        });
        builder.addCase(searchTeams.pending, (state) => {
            state.loadingTeam = true;
            state.listTeams = [];
        });
        builder.addCase(
            searchTeams.fulfilled,
            (state, action: PayloadAction<IWorkGroupsItem[]>) => {
                state.loadingTeam = false;
                state.listTeams = action.payload;
            },
        );
        builder.addCase(searchTeams.rejected, (state) => {
            state.loadingTeam = false;
        });
    },
});

export const {
    resetListOperators,
    loadOperators,
    addOperator,
    resetAddedNotificationEmail,
    handleDeleteOperator,
    resetAddedOperators,
    resetListTeam,
} = teamOperators.actions;

export default teamOperators.reducer;
