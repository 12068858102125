/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Grid, LoadingButton } from "@enerbit/base";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { schemaOrder } from "../../../helpers";
import { useResetStore } from "../../../hooks/useResetStore";
import type { IPostOrders } from "../../../models/IPostOrders";
import { editOrder, useAppSelector } from "../../../store";
import { useAppDispatch } from "../../../store/store";
import { coordination } from "../../../utils/coordinations";
import { OrderCancellationReason } from "../cancellation-reason";
import { InfoOrder } from "../info-order";
import { LeadPartnerContent } from "../lead-partner";
import Notifications from "../nofitifications/Notifications";
import { Observations } from "../observations";

export const EditProvider = () => {
    const { enqueueSnackbar } = useSnackbar();
    const {
        selectedOrder,
        addedEntities,
        listEntities,
        entity,
        isDone,
        loadingOrder,
        errorOrder,
        action,
    } = useAppSelector((state) => state.initialForm);

    const { listOrders } = useAppSelector(
        (state) => state.listOrders.orders[entity ?? "LD"],
    );

    const { detailsOrder } = useAppSelector((state) => state.detailProgress);
    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const methods = useForm<IPostOrders>({
        resolver: yupResolver(schemaOrder),
        mode: "onChange",
    });

    const { resetFormContent } = useResetStore();

    const { handleSubmit, setValue, reset, trigger, watch } = methods;

    const availableCoordinations = coordination.find(
        ({ code }) => code === selectedOrder?.order_type.code,
    );

    useEffect(() => {
        reset({
            ...selectedOrder,
            order_type_id: selectedOrder?.order_type.id,
        });
    }, [selectedOrder]);

    const onSubmit = async (formData: IPostOrders) => {
        if (!(await trigger())) return;

        const newOrderStart = new Date(formData.planned_date_begin).getTime();
        const newOrderEnd = new Date(formData.planned_date_end).getTime();
        const workGroupId = watch("work_group_id");

        const overlap = listOrders.some((order) => {
            if (!workGroupId) return false;
            if (order.work_group_id !== workGroupId) return false;

            const existingOrderStart = new Date(
                order.planned_date_begin,
            ).getTime();
            const existingOrderEnd = new Date(order.planned_date_end).getTime();

            const startDiff = Math.abs(newOrderStart - existingOrderEnd);
            const endDiff = Math.abs(newOrderEnd - existingOrderStart);

            return (
                startDiff < 900000 ||
                endDiff < 900000 ||
                (newOrderStart < existingOrderEnd &&
                    newOrderEnd > existingOrderStart)
            );
        });

        if (overlap) {
            enqueueSnackbar(
                "La cuadrilla debe tener 15 minutos de diferencia entre las ordenes de servicio.",
                {
                    variant: "warning",
                },
            );
            return;
        }

        const selectedCoordinations =
            formData.form_data?.criteria?.coordinations.filter(
                (value) => value !== "pre_enlisted_materials",
            ) || [];

        const allCoordinationsSelected =
            selectedCoordinations.length ===
            availableCoordinations?.coordinations.filter(
                ({ value }) => value !== "pre_enlisted_materials",
            ).length;

        let newStatus = "";
        if (allCoordinationsSelected && workGroupId) {
            newStatus = "pending";
        } else {
            newStatus = "pending_assignment";
        }

        const updatedData = {
            ...formData,
            status: newStatus,
        };

        dispatch(
            editOrder({ id: selectedOrder?.id || "", order: updatedData }),
        );
        resetFormContent();
        navigate("/");
    };

    useEffect(() => {
        if (addedEntities.length > 0) {
            addedEntities.map(({ id }, index) => {
                setValue(`order_entities.${index}.assignee_id`, id);
                setValue(
                    `order_entities.${index}.entity_id`,
                    listEntities.find(({ code }) => code === entity)?.id || "",
                );
            });
        }
    }, [addedEntities]);

    useEffect(() => {
        if (errorOrder) {
            enqueueSnackbar(
                "No se pudo guardar los cambios, por favor intente nuevamente.",
                {
                    variant: "error",
                },
            );
        }
    }, [errorOrder]);

    useEffect(() => {
        if (isDone) {
            enqueueSnackbar("Los cambios fueron guardados con éxito.", {
                variant: "success",
            });
            resetFormContent();
            navigate("/");
        }
    }, [isDone]);

    return (
        <FormProvider {...methods}>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
                <Grid container rowSpacing={3}>
                    <LeadPartnerContent />
                    {(action?.activity?.code === "CC" ||
                        action?.activity?.code === "REMOV_CE_NO_INST") && (
                        <OrderCancellationReason />
                    )}
                    <InfoOrder />
                    <Observations />
                    <Notifications />
                    <Grid item xs={12} display="grid">
                        <LoadingButton
                            disabled={
                                selectedOrder?.status !== "pending" &&
                                detailsOrder
                            }
                            id="edit-order"
                            loading={loadingOrder}
                            type="submit"
                            color="secondary"
                            variant="contained"
                        >
                            Guardar cambios
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Box>
        </FormProvider>
    );
};
