import {
    Avatar,
    AvatarGroup,
    Box,
    Chip,
    Divider,
    Grid,
    Tooltip,
    Typography,
    capitalize,
    dayjs,
} from "@enerbit/base";
import { type FC, useMemo } from "react";
import type { IDayKey, IWorkGroupsItem } from "../../../models";
import { useAppSelector } from "../../../store";
import { stringToColor } from "../../../utils";

const TeamItem: FC<IWorkGroupsItem> = ({
    operators,
    state,
    city,
    schedules,
    mobility,
}) => {
    useAppSelector((state) => state.teamOperators);

    const allMeasures = operators.flatMap(
        ({ pii }) => pii.form_data?.measurement_type_trained || [],
    );

    const uniqueMeasures = Array.from(new Set(allMeasures));

    const formatTime = (time: string): string => {
        return dayjs(time).format("hh:mm A");
    };

    const dayInfo = useMemo(() => {
        const info: Record<string, { active: boolean; details: string[] }> = {};
        const dayNames = {
            monday: "L",
            tuesday: "Ma",
            wednesday: "Mi",
            thursday: "J",
            friday: "V",
            saturday: "S",
            sunday: "D",
        };

        const addInfo = (
            day: string,
            scheduleType: string,
            since: string,
            until: string,
        ) => {
            const scheduleDescription =
                scheduleType === "overtime" ? "Recargo" : "Ordinario";
            if (!info[day]) {
                info[day] = { active: true, details: [] };
            }
            const detailString = `${scheduleDescription}: ${formatTime(
                since,
            )} - ${formatTime(until)}`;
            if (!info[day].details.includes(detailString)) {
                info[day].details.push(detailString);
            }
        };

        Object.entries({
            ...schedules.week,
            ...(schedules.weekend ?? {}),
        }).forEach(([dayName, day]) => {
            if (day !== null) {
                addInfo(
                    dayNames[dayName as keyof typeof dayNames],
                    "ordinary",
                    day.since,
                    day.until,
                );
            }
        });

        schedules.overtime?.forEach(({ day, since, until }) => {
            const dayKey: IDayKey = day as IDayKey;
            const initial = dayNames[dayKey];
            addInfo(initial, "overtime", since, until);
        });

        return info;
    }, [schedules]);

    const renderTooltipContent = (details: string[]) => (
        <Box>
            {details.map((detail, index) => (
                <Typography
                    key={index.toString()}
                    component="div"
                    color={"white"}
                >
                    {detail}
                </Typography>
            ))}
        </Box>
    );

    const teamName = useMemo(() => {
        const namesBeforeAt = operators.map(
            ({ username }) => username.split("@")[0],
        );
        const joinedNamesWithHyphen = namesBeforeAt.join("-");
        return `${joinedNamesWithHyphen}`;
    }, [operators]);

    return (
        <Grid item xs={12}>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "14px",
                    border: "1px solid #667085",
                    height: "100%",
                    padding: "16px",
                    gap: 1,
                    zIndex: "0!important",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                        Cuadrilla {teamName}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        gap: 1,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: "auto",
                            gap: 2,
                        }}
                    >
                        <Box width={"75%"}>
                            <Typography variant="body1">
                                Departamento: <b>{state}</b>
                            </Typography>
                            <Typography
                                variant="body1"
                                style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                            >
                                Ciudad:{city ?? ""}
                            </Typography>
                        </Box>
                        <AvatarGroup
                            total={operators.length}
                            max={3}
                            sx={{
                                ".MuiAvatarGroup-avatar": {
                                    width: 25,
                                    height: 25,
                                    fontSize: 10,
                                },
                            }}
                        >
                            {operators.map(({ pii, username }, index) => (
                                <Tooltip
                                    title={pii.full_name}
                                    key={index.toString()}
                                >
                                    <Avatar
                                        alt={username}
                                        src={
                                            pii?.form_data?.image_profile
                                                ?.photography_base64
                                        }
                                        sx={{
                                            width: 25,
                                            height: 25,
                                            backgroundColor:
                                                stringToColor(username),
                                            fontSize: 10,
                                        }}
                                    >
                                        {username.substring(0, 2).toUpperCase()}
                                    </Avatar>
                                </Tooltip>
                            ))}
                        </AvatarGroup>
                    </Box>
                    <Divider />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: 1,
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                gap: 1,
                                overflowX: "auto",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {mobility.map((item, index) => (
                                <Chip
                                    key={index.toString()}
                                    label={capitalize(item)}
                                    sx={{
                                        backgroundColor: "#E4ECF7",
                                        color: "#505780",
                                        fontWeight: "bold",
                                        borderRadius: "10px",
                                    }}
                                />
                            ))}
                            {uniqueMeasures.map((measurement, index) => (
                                <Chip
                                    key={index.toString()}
                                    label={capitalize(measurement)}
                                    sx={{
                                        backgroundColor: "#E4ECF7",
                                        color: "#505780",
                                        fontWeight: "bold",
                                        borderRadius: "10px",
                                    }}
                                />
                            ))}
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                gap: 1,
                                overflowX: "auto",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {["L", "Ma", "Mi", "J", "V", "S", "D"].map(
                                (day, index) => (
                                    <Tooltip
                                        key={index.toString()}
                                        title={
                                            dayInfo[day]?.active
                                                ? renderTooltipContent(
                                                      dayInfo[day].details,
                                                  )
                                                : "No disponible"
                                        }
                                    >
                                        <Chip
                                            color="primary"
                                            variant="outlined"
                                            label={day}
                                            disabled={!dayInfo[day]?.active}
                                            sx={{ borderRadius: "5px" }}
                                        />
                                    </Tooltip>
                                ),
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Grid>
    );
};

export default TeamItem;
