import { Box, Grid, Typography } from "@enerbit/base";
import { useEffect, useMemo } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as singleSpa from "single-spa";
import { CertificationsAssociated } from ".";
import { EEntity } from "../../../helpers";
import type { OrderEntityGetOrders } from "../../../models";
import {
    getCurrentEntity,
    getInitialEntities,
    getOrder,
    getSmartBit,
} from "../../../store";
import {
    setAction,
    setEntity,
} from "../../../store/slices/initial-form/initialForm";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import Backbutton from "../back-button/BackButton";

export const Certifications = () => {
    const dispatch = useAppDispatch();
    const { selectedOrder, addedEntities, action } = useAppSelector(
        (state) => state.initialForm,
    );

    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const mainEntity: OrderEntityGetOrders | null = useMemo(() => {
        if (!selectedOrder || addedEntities.length === 0) return null;

        const targetId =
            action?.entity === EEntity.Client && addedEntities[0].secondary_id
                ? addedEntities[0].secondary_id
                : addedEntities[0].id;

        if (selectedOrder.order_entities.length === 1) {
            const singleEntity = selectedOrder.order_entities[0];
            return singleEntity.status !== "cancel" ? singleEntity : null;
        }

        const entity = selectedOrder.order_entities.find(
            (entity) =>
                entity.assignee_id === targetId && entity.status !== "cancel",
        );

        return entity || null;
    }, [selectedOrder, action, addedEntities]);

    // This useMemo is used for get the associated entities if the entity is a cabinet
    const associatedEntities = useMemo(() => {
        if (!selectedOrder || selectedOrder.order_entities.length <= 1) {
            return [];
        }

        return selectedOrder.order_entities.filter(
            (entity) =>
                entity.assignee_id !== addedEntities[0].id &&
                entity.status !== "cancel",
        );
    }, [selectedOrder, addedEntities]);

    useEffect(() => {
        if (mainEntity) {
            console.log({
                order: selectedOrder,
                mainLead: mainEntity,
            });

            const appName = "app-certifications";

            const loadAndMountApp = async () => {
                const appStatus = singleSpa.getAppStatus(appName);

                if (appStatus === "MOUNTED") {
                    await singleSpa.unloadApplication(appName, {
                        waitForUnmount: true,
                    });
                }

                if (!singleSpa.getAppNames().includes(appName)) {
                    singleSpa.registerApplication({
                        name: appName,
                        app: () =>
                            System.import("@enerBit/certifications").then(
                                (module) => {
                                    if (
                                        module.bootstrap &&
                                        module.mount &&
                                        module.unmount
                                    ) {
                                        return {
                                            bootstrap: module.bootstrap,
                                            mount: module.mount,
                                            unmount: module.unmount,
                                        };
                                    }
                                    throw new Error(
                                        "Module does not export Single SPA lifecycle methods.",
                                    );
                                },
                            ),
                        activeWhen: (location) =>
                            location.hash.includes("/orders/certifications"),
                        customProps: {
                            order: selectedOrder,
                            mainLead: mainEntity,
                        },
                    });

                    singleSpa.start();
                } else if (
                    appStatus === "NOT_MOUNTED" ||
                    appStatus === "NOT_LOADED"
                ) {
                    await singleSpa.triggerAppChange();
                }
            };

            loadAndMountApp();

            return () => {
                const appStatus = singleSpa.getAppStatus(appName);
                if (appStatus === "MOUNTED") {
                    singleSpa
                        .unloadApplication(appName, { waitForUnmount: true })
                        .then(() => {
                            singleSpa.unregisterApplication(appName);
                        })
                        .catch((err) =>
                            console.error("Error unloading application:", err),
                        );
                }
            };
        }
    }, [mainEntity, selectedOrder]);

    useEffect(() => {
        const entity = searchParams.get("entity");
        const assigneeId = searchParams.get("assignee");

        if (!selectedOrder || !entity || !assigneeId) return;

        dispatch(setEntity(entity as EEntity));

        if (!action?.isFromSearch) {
            dispatch(
                getCurrentEntity({
                    entityCode: entity as EEntity,
                    assignee: assigneeId,
                }),
            );
        }

        if (entity !== EEntity.Smartbit) {
            dispatch(getInitialEntities(selectedOrder.order_entities));
        } else {
            dispatch(
                getSmartBit({
                    id: selectedOrder.order_entities[0].assignee_id,
                    isCreate: true,
                }),
            );
        }

        dispatch(
            setAction({
                type: "Certifications",
                entity: entity as EEntity,
                isFromSearch: action?.isFromSearch,
            }),
        );
    }, [selectedOrder]);

    useEffect(() => {
        if (!id) return;
        dispatch(getOrder(id));
    }, []);

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                        <Backbutton
                            onClick={() => {
                                // if (!action?.isFromSearch) {
                                //   dispatch(resetLeads());
                                // }
                                navigate("/");
                            }}
                        />{" "}
                        <Typography
                            color="primary"
                            sx={{
                                fontSize: "28px",
                                fontWeight: 700,
                                display: "flex",
                                flex: 1,
                            }}
                        >
                            Ver acta
                        </Typography>
                    </Box>
                </Grid>
                {associatedEntities.length > 0 && selectedOrder && (
                    <CertificationsAssociated
                        associatedLeads={associatedEntities}
                        order={selectedOrder}
                    />
                )}
            </Grid>
            {mainEntity && (
                <Box
                    sx={{
                        border: "1px solid #A3A9B6",
                        borderRadius: "14px",
                        p: 2,
                        mt: 3,
                    }}
                >
                    <Grid item>
                        <Typography
                            color="primary"
                            sx={{
                                fontSize: "21px",
                                fontWeight: 700,
                                mb: "12px",
                                display: "flex",
                                flex: 1,
                            }}
                        >
                            Acta principal
                        </Typography>
                    </Grid>
                    <div id="single-spa-application:app-certifications" />
                </Box>
            )}
        </>
    );
};
