import { api } from "@enerbit/base";
import type { ICompany } from "../models";

export const getAllCompany = async () => {
    const { data } = await api.get("/ops-users/companies/");
    const formatCompanies = data.items.map((company: ICompany) => ({
        label: company.name,
        id: company.id,
    }));
    return formatCompanies;
};

export const getTeamByCompanyId = async (companyID: string) => {
    const { data } = await api.get(
        `/work-groups/work-groups?company_id=${companyID}`,
    );
    return data.items;
};
