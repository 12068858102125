import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import type {
    IRespWorkGroups,
    IResponseOperators,
    IWorkGroupsItem,
    OrderOperatorGetOrders,
} from "../../../models";

export const getOperators = createAsyncThunk(
    "teamOperators/getOperators",
    async (email: string) => {
        const { data } = await api.get<IResponseOperators>(
            `/ops-users/operators/?username=${email}&page=0&size=5&rol_code=operacion_energia_digital,opr&is_active=true`,
        );
        const operators: OrderOperatorGetOrders[] = data.items.map(
            ({ id, created_at, updated_at, pii, rol, username, company }) => ({
                operator_id: id,
                created_at: new Date(created_at).toISOString(), // convertir a string
                updated_at: updated_at
                    ? new Date(updated_at).toISOString()
                    : null, // convertir a string o null
                pii: {
                    full_name: pii ? pii?.full_name : "",
                    legal_id_type: pii ? pii?.legal_id_type : "",
                    legal_id_code: pii ? pii?.legal_id_code : "",
                    phone: pii ? pii?.phone : "",
                    rol: rol.name,
                    email: username,
                    company: {
                        name: company.name,
                        legal_id_code: company.legal_id_code,
                        address: company.address,
                        phone: company.phone,
                        email: company.address,
                        id: company.id,
                        created_at: new Date(company.created_at).toISOString(), // convertir a string
                        updated_at: company.updated_at
                            ? new Date(company.updated_at).toISOString()
                            : null, // convertir a string o null
                    },
                },
            }),
        );
        return operators;
    },
);

export const getTeams = createAsyncThunk(
    "teamOperators/getTeams",
    async (email: string) => {
        const { data } = await api.get<IRespWorkGroups>(
            `/work-groups/work-groups?page=0&size=5&username=${email}`,
        );
        return data.items;
    },
);

export const addOrderOperator = createAsyncThunk(
    "teamOperators/addOrderOperator",
    async (
        {
            order_id,
            operator,
        }: {
            order_id: string;
            operator: OrderOperatorGetOrders;
        },
        { rejectWithValue },
    ) => {
        try {
            const { data } = await api.post<{ id: string }>(
                "/service-orders/order-operators",
                {
                    order_id,
                    operator_id: operator.operator_id,
                },
            );
            return { ...operator, id: data.id };
        } catch (error) {
            return rejectWithValue("Ha ocurrido un error.");
        }
    },
);

export const deleteOrderOperator = createAsyncThunk(
    "teamOperators/deleteOrderOperator",
    async (
        { id, operator_id }: { id: string; operator_id: string },
        { rejectWithValue },
    ) => {
        try {
            await api.delete(
                `/service-orders/order-operators/${id}?operator_id=${operator_id}`,
            );
            return id;
        } catch (error) {
            return rejectWithValue("Ha ocurrido un error.");
        }
    },
);

export const deleteAllOrderOperators = createAsyncThunk(
    "teamOperators/deleteAllOrderOperators",
    async (data: { group_id?: string; id: string }[], { rejectWithValue }) => {
        try {
            const deletePromises = data.map(({ group_id, id }) =>
                api.delete(
                    `/service-orders/order-operators/${group_id}?operator_id=${id}`,
                ),
            );
            await Promise.all(deletePromises);
        } catch (error) {
            return rejectWithValue("Ha ocurrido un error.");
        }
    },
);

export const getTeam = createAsyncThunk(
    "teamOperators/getTeam",
    async ({ id }: { id: string }) => {
        const { data } = await api.get<IWorkGroupsItem>(
            `/work-groups/work-groups/${id}`,
        );

        return data;
    },
);

export const searchTeams = createAsyncThunk(
    "teamOperators/searchTeams",
    async (
        {
            id,
            state,
            city,
            start,
            end,
            company_id,
            measurement_type,
        }: {
            id?: string;
            state?: string;
            city?: string;
            start: string;
            end: string;
            company_id?: string;
            measurement_type?: string;
        },
        { rejectWithValue },
    ) => {
        try {
            const [teamsResponse, teamResponse] = await Promise.allSettled([
                start && end
                    ? api.get<IRespWorkGroups>(
                          `/work-groups/available-work-groups?&start=${start}&end=${end}&state=${state}&city=${city}${company_id ? `&company_id=${company_id}` : ""}${measurement_type ? `&measurement_type_trained=${measurement_type}` : ""}`,
                      )
                    : Promise.resolve(null),
                id && start && end
                    ? api.get<IWorkGroupsItem>(`/work-groups/work-groups/${id}`)
                    : Promise.resolve(null),
            ]);

            let teams: IWorkGroupsItem[] = [];
            if (teamsResponse.status === "fulfilled" && teamsResponse.value) {
                teams =
                    teamsResponse.value.data.items.filter(
                        (team) => team.id !== id,
                    ) || [];
            }

            if (
                id &&
                teamResponse.status === "fulfilled" &&
                teamResponse.value
            ) {
                const selectedTeam = teamResponse.value.data;
                teams.unshift(selectedTeam);
            }

            return teams.slice(0, 3);
        } catch (error) {
            return rejectWithValue("Error al consultar cuadrillas.");
        }
    },
);
