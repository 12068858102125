import { Grid, Typography } from "@enerbit/base";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { TeamItemSkeleton } from ".";
import { getTeam, useAppDispatch, useAppSelector } from "../../../store";
import TeamItem from "./TeamItem";

const TeamContainer = () => {
    const { setValue, watch } = useFormContext();

    const { action } = useAppSelector((state) => state.initialForm);
    const { addedTeam, loadingTeam } = useAppSelector(
        (state) => state.teamOperators,
    );

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (action?.type === "Create") {
            setValue("work_group_id", addedTeam?.id);
        }
    }, [addedTeam]);

    useEffect(() => {
        if (action?.type !== "Create" && watch("work_group_id")) {
            dispatch(
                getTeam({
                    id: watch("work_group_id"),
                }),
            );
        }
    }, [watch("work_group_id"), action]);

    return (
        <>
            <Grid item xs={12}>
                <Typography
                    color="primary"
                    sx={{ fontSize: "22px", fontWeight: 400 }}
                >
                    Cuadrilla
                </Typography>
            </Grid>
            {loadingTeam ? (
                <TeamItemSkeleton />
            ) : (
                <>{addedTeam && <TeamItem {...addedTeam} />}</>
            )}
        </>
    );
};

export default TeamContainer;
