import { createOrderAllowedRoles, mapRolesToEntities } from "../const/roles";
import type { RoleCode } from "../models/IRoles";
import type { EEntity } from "./common";

export const definePermissions = (role: RoleCode, entity: EEntity | null) => {
    if (!entity) return false;
    return (
        mapRolesToEntities[role].includes(entity) &&
        createOrderAllowedRoles.includes(role)
    );
};
