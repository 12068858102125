import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Skeleton,
    enerbitColors,
} from "@enerbit/base";

const TeamItemSkeleton = () => {
    return (
        <Grid item xs={12} md={4}>
            <Card
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    border: `1px solid ${enerbitColors.neutral[300]}`,
                    borderRadius: "14px",
                    height: "100%",
                }}
            >
                <CardHeader
                    title={
                        <Skeleton animation="wave" height={20} width="250px" />
                    }
                />
                <CardContent>
                    <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        mb={1}
                    >
                        <Skeleton animation="wave" height={15} width="150px" />
                        <Skeleton
                            animation="wave"
                            variant="circular"
                            width={25}
                            height={25}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: 2,
                        }}
                    >
                        <Box
                            display="flex"
                            justifyContent="space-around"
                            alignItems="center"
                            gap={1}
                        >
                            {Array.from(new Array(4)).map((_, index) => (
                                <Skeleton
                                    key={index}
                                    animation="wave"
                                    variant="rectangular"
                                    width={80}
                                    height={30}
                                />
                            ))}
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            gap={1}
                            mt={2}
                        >
                            {Array.from(new Array(7)).map((_, index) => (
                                <Skeleton
                                    key={index}
                                    variant="rectangular"
                                    animation="wave"
                                    height={30}
                                    width={30}
                                />
                            ))}
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default TeamItemSkeleton;
