import { api } from "@enerbit/base";

export const uploadImage = async (image: File) => {
    const bodyFormData = new FormData();
    bodyFormData.append("file", image);

    const { data } = await api.put<{ file_path: string }>(
        `/service-orders-certificates/upload-file`,
        bodyFormData,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            transformRequest: () => {
                return bodyFormData;
            },
        },
    );

    return data.file_path;
};

export const loadImage = async (url_image: string) => {
    return await api
        .get<{ url: string }>(
            `/service-orders-certificates/authenticate-file?url_path=${url_image}`,
        )
        .then(({ data }) => data.url);
};
