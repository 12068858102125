import { Grid, Typography } from "@enerbit/base";
import { useFormContext } from "react-hook-form";
import { useAppSelector } from "../../../store/store";
import { EntityContainer } from "../entity";
import { MapOrder } from "../map/MapOrder";

export const LeadPartnerContent = () => {
    const { watch } = useFormContext();
    const { action } = useAppSelector((state) => state.initialForm);

    return (
        <Grid container item xs={12} spacing={3}>
            <Grid item xs={12}>
                <Typography
                    color="primary"
                    sx={{ fontSize: "28px", fontWeight: 400, mb: "12px" }}
                >
                    Información del servicio
                </Typography>
            </Grid>
            <EntityContainer />
            {!!watch("order_cancel") &&
                !!watch("order_cancel.form_data.geolocalization") &&
                action?.type === "Consult" && (
                    <>
                        <MapOrder
                            latitude={watch(
                                "order_cancel.form_data.geolocalization.latitude",
                            )}
                            longitude={watch(
                                "order_cancel.form_data.geolocalization.longitude",
                            )}
                        />
                    </>
                )}
            {!!watch("order_done") &&
                watch("order_done.form_data.geolocalization") &&
                action?.type === "Consult" && (
                    <>
                        <MapOrder
                            latitude={watch(
                                "order_done.form_data.geolocalization.latitude",
                            )}
                            longitude={watch(
                                "order_done.form_data.geolocalization.longitude",
                            )}
                        />
                    </>
                )}
        </Grid>
    );
};
