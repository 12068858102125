import {
    Box,
    Checkbox,
    type GridColDef,
    type GridRenderCellParams,
    type GridRowParams,
    Radio,
    Typography,
    esES,
} from "@enerbit/base";
import { type FC, useCallback, useEffect, useState } from "react";
import { CustomPagination } from "../../../common/CustomPagination";
import { StyledDataGrid } from "../../../common/StyledDataGrid";
import type { ILeadItem } from "../../../models";
import { getLeads } from "../../../store";
import {
    handleLead,
    handleLeads,
    setPage,
    setPageSize,
} from "../../../store/slices/table-address/tableAddress";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAppDispatch, useAppSelector } from "../../../store/store";

interface Props {
    multiple?: boolean;
}

export const AddressTableLead: FC<Props> = ({ multiple }) => {
    const { filter } = useAppSelector((state) => state.initialForm);
    const { listLeads, loading, size, rowCount, selectedLeads, page, name } =
        useAppSelector((state) => state.tableAddress);
    const dispatch = useAppDispatch();

    const styledName = { display: "flex", alignItems: "center" };

    const columns: GridColDef[] = [
        {
            field: "display_name",
            headerName: "Nombre",
            flex: 1,
            align: "left",
            headerAlign: "center",
            renderCell: ({
                value,
                row,
            }: GridRenderCellParams<string, ILeadItem>) => {
                const index = listLeads.findIndex(({ id }) => row.id == id);
                const { city, address } = row;
                return (
                    <Box>
                        {multiple ? (
                            <Box sx={styledName}>
                                <Checkbox
                                    id={`address-${page}-${index}`}
                                    checked={
                                        !!selectedLeads.find(
                                            ({ id }) => id === row.id,
                                        )
                                    }
                                    color="secondary"
                                    size="small"
                                    sx={{ mb: 0.5 }}
                                />
                                <Typography
                                    sx={{
                                        fontWeight: "bold",
                                        fontSize: "17px",
                                    }}
                                >
                                    {value}
                                </Typography>
                            </Box>
                        ) : (
                            <Box sx={styledName}>
                                <Radio
                                    id={`address-${page}-${index}`}
                                    checked={
                                        !!selectedLeads.find(
                                            ({ id }) => id === row.id,
                                        )
                                    }
                                    color="secondary"
                                    size="small"
                                    sx={{ mb: 0.5 }}
                                />
                                <Typography
                                    sx={{
                                        fontWeight: "bold",
                                        fontSize: "17px",
                                    }}
                                >
                                    {value}
                                </Typography>
                            </Box>
                        )}

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                ml: 1.5,
                                mb: 1,
                            }}
                        >
                            <Typography color="neutral.main">
                                {city || "No registra"}
                            </Typography>
                            <Typography color="neutral.main">
                                {address || "No registra"}
                            </Typography>
                        </Box>
                    </Box>
                );
            },
            sortable: false,
        },
    ];

    const getRowSpacing = useCallback((params: any) => {
        return {
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
        };
    }, []);

    const getRowClassName = (params: GridRowParams): string => {
        return selectedLeads.some((lead) => lead.id === params.id)
            ? "selected-row"
            : "";
    };

    const [isFirstRender, setIsFirstRender] = useState(true);

    useEffect(() => {
        if (!isFirstRender) {
            if (filter) {
                dispatch(getLeads({ email: filter, page, size, name }));
            }
        } else {
            dispatch(setPage(1));
            setIsFirstRender(false);
        }
    }, [page, size, isFirstRender]);

    return (
        <Box sx={{ width: "100%" }}>
            <StyledDataGrid
                disableVirtualization
                paginationMode="server"
                disableColumnMenu
                rowSpacingType="border"
                getRowSpacing={getRowSpacing}
                getRowClassName={getRowClassName}
                rows={listLeads}
                selectionModel={selectedLeads.map(({ id }) => id)}
                getRowId={(row) => row.id}
                onSelectionModelChange={(e) => {
                    if (e.length > 0) {
                        const selectedIDs = new Set(e);

                        const selectedRowData = listLeads.filter((row) =>
                            selectedIDs.has(row.id),
                        )[0];
                        if (multiple) {
                            dispatch(handleLeads(selectedRowData));
                        } else {
                            dispatch(handleLead(selectedRowData));
                        }
                    }
                }}
                headerHeight={0}
                rowHeight={100}
                columns={columns}
                pageSize={size}
                rowsPerPageOptions={[5, 10, 20, 50]}
                rowCount={rowCount}
                onPageChange={(page) => dispatch(setPage(page))}
                onPageSizeChange={(pageSize) => dispatch(setPageSize(pageSize))}
                loading={loading}
                autoHeight
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                pagination
                components={{
                    Pagination: CustomPagination,
                }}
            />
        </Box>
    );
};
